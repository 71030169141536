<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách thống kê refer</h6>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                  <div class="row mb-6">
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Sender phone:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          placeholder="Nhập số điện thoại..."
                          v-model="paramFilter.sender_phone"
                      />
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Receiver phone:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          placeholder="Nhập số điện thoại..."
                          v-model="paramFilter.receiver_phone"
                      />
                    </div>

                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Nhiệm vụ:</label>
                      <select
                          class="form-control form-select"
                          data-col-index="7"
                          v-model="paramFilter.mission_id"
                      >
                        <option value="">Tất cả</option>
                        <option v-for="(item, index) in missions" :key="index" :value="item.id">{{ item.title }}</option>
                      </select>
                    </div>

                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Trạng thái hoàn thành:</label>
                      <select
                          class="form-control form-select"
                          data-col-index="7"
                          v-model="paramFilter.status"
                      >
                        <option value="">Tất cả</option>
                        <option value=0>Đang làm</option>
                        <option value=1>Hoàn thành</option>
                        <option value=3>Hết hạn</option>
                        <option value=4>Vượt giới hạn trong ngày</option>
                        <option value=5>Vượt giới hạn trong campaign</option>
                        <option value=6>Chưa đủ điều kiện</option>
                      </select>
                    </div>
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Trạng thái nhận thưởng:</label>
                      <select
                          class="form-control form-select"
                          data-col-index="7"
                          v-model="paramFilter.status_bonus"
                      >
                        <option value="">Tất cả</option>
                        <option value=0>Đang làm</option>
                        <option value=1>Hoàn thành</option>
                        <option value=2>Lỗi</option>
                        <option value=3>Chưa đủ điều kiện</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-6">
                    <div class="col-lg-4 mb-lg-0 mb-6">
                      <label>Thời gian tham gia:</label>
                      <div class="input-daterange input-group" id="kt_datepicker">
                        <input
                            type="date"
                            v-model="paramFilter.date_join_from"
                            class="form-control datatable-input"
                            name="start"

                            placeholder="Từ"
                        />
                        <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                        </div>
                        <input
                            type="date"
                            v-model="paramFilter.date_join_to"
                            class="form-control datatable-input"
                            name="end"
                            placeholder="Đến"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 mb-lg-0 mb-6">
                      <label>Thời gian hoàn thành:</label>
                      <div class="input-daterange input-group" id="kt_datepicker1">
                        <input
                            type="date"
                            v-model="paramFilter.date_finish_from"
                            class="form-control datatable-input"
                            name="start"
                            placeholder="Từ"
                        />
                        <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                        </div>
                        <input
                            type="date"
                            v-model="paramFilter.date_finish_to"
                            class="form-control datatable-input"
                            name="end"
                            placeholder="Đến"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                      </button>
                    </div>
                  </div>
                </b-form>

              </div>

              <div>
                <b-button v-b-toggle.collapse-3 class="m-1">Thu gọn</b-button>
                <b-collapse visible id="collapse-3">
                  <b-card-group deck>
                    <b-card
                        border-variant="primary"
                        header="Nhiệm vụ KYC"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="center"
                    >
                      <b-card-text>
                        <span>Tổng: {{ extra_data.mission.kyc.total }}</span><br>
                        <span>Thành công: {{ extra_data.mission.kyc.success }}</span><br>
                        <span>Chưa hoàn thành: {{ extra_data.mission.kyc.pending }}</span><br>
                      </b-card-text>
                    </b-card>
                    <b-card
                        border-variant="primary"
                        header="Nhiệm vụ nạp tiền"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="center"
                    >
                      <b-card-text>
                        <span>Tổng: {{ extra_data.mission.deposit.total }}</span><br>
                        <span>Thành công: {{ extra_data.mission.deposit.success }}</span><br>
                        <span>Chưa hoàn thành: {{ extra_data.mission.deposit.pending }}</span><br>
                      </b-card-text>
                    </b-card>
                    <b-card
                        border-variant="primary"
                        header="Trạng thái"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="center"
                    >
                      <b-card-text>
                        <span>Tổng: {{ extra_data.report_status.success + extra_data.report_status.pending + extra_data.report_status.expired + extra_data.report_status.limit_in_day + extra_data.report_status.limit_in_camp + extra_data.report_status.kyc_and_link_bank }}</span><br>
                        <span>Thành công: {{ extra_data.report_status.success }}</span><br>
                        <span>Đang làm: {{ extra_data.report_status.pending }}</span><br>
                        <span>Hết hạn: {{ extra_data.report_status.expired }}</span><br>
                        <span>Limit in day: {{ extra_data.report_status.limit_in_day }}</span><br>
                        <span>Limit in campaign: {{ extra_data.report_status.limit_in_camp }}</span><br>
                        <span>Chưa đủ điều kiện: {{ extra_data.report_status.kyc_and_link_bank }}</span><br>
                      </b-card-text>
                    </b-card>
                    <b-card
                        border-variant="primary"
                        header="Trạng thái nhận thưởng"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="center"
                    >
                      <b-card-text>
                        <span>Tổng: {{ extra_data.report_status_bonus.success + extra_data.report_status_bonus.pending + extra_data.report_status_bonus.error + extra_data.report_status_bonus.unqualified }}</span><br>
                        <span>Thành công: {{ extra_data.report_status_bonus.success }}</span><br>
                        <span>Đang làm: {{ extra_data.report_status_bonus.pending }}</span><br>
                        <span>Lỗi: {{ extra_data.report_status_bonus.error }}</span><br>
                        <span>Chưa đủ điều kiện: {{ extra_data.report_status_bonus.unqualified }}</span><br>

                      </b-card-text>
                    </b-card>
                  </b-card-group>
                </b-collapse>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >
                <template #cell(sender)="data">
                  <span> SĐT : {{ data.item.sender.phone }} </span> <br />
                  <span> User ID: {{ data.item.sender.uuid }} </span><br />
                  <span v-if="data.item.sender.name">
                    họ tên: {{ data.item.sender.name }}
                  </span>
                </template>

                <template #cell(receiver)="data">
                  <span> SĐT : {{ data.item.receiver.phone }} </span> <br />
                  <span> User ID: {{ data.item.receiver.uuid }} </span><br />
                  <span v-if="data.item.receiver.name">
                    họ tên: {{ data.item.receiver.name }}
                  </span>
                </template>

                <template #cell(created_at)="data">
                  <span> {{ data.item.created_at | formatDateTime }} </span> <br />
                </template>

                <template #cell(steps)="data">
                  <span> {{ buildStep(data.item) }} </span> <br />
                </template>

                <template #cell(status)="data">
                  <span :class="[
                      {
                        'badge mr-1 badge-success': data.item.status.value == 1,
                      },
                      {
                        'badge mr-1 badge-primary': data.item.status.value == 0,
                      },
                      {
                        'badge mr-1 badge-danger': data.item.status.value != 0 && data.item.status.value != 1,
                      },
                  ]"> {{ data.item.status.label }} </span> <br />
                </template>

                <template #cell(updated_at)="data">
                  <span> {{ data.item.updated_at | formatDateTime }} </span> <br />
                </template>

                <template #cell(action)="data">
                  <router-link
                      v-if="data.item.status.value === 1 || data.item.status.value == 4 || data.item.status.value == 5 || data.item.status.value == 6"
                    :to="`/campaign/report/refer-detail/${data.item.id}`"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    style="color: #0093ca"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Chi tiết</span>
                    </a>
                  </router-link>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.per_page"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("Campaign");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        sender_phone: "",
        receiver_phone: "",
        mission_id: "",
        status_bonus: "",
        status: "",
        date_join_from: '',
        date_join_to: '',
        date_finish_from: '',
        date_finish_to: '',
        limit: 20,
      },
      fieldsLog: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "sender",
          label: "Người giới thiệu",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "receiver",
          label: "Người được giới thiệu",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Thời gian tham gia",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "steps",
          label: "Nhiệm vụ hiện tại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái hoàn thành",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "updated_at",
          label: "Thời gian hoàn thành",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],

      missions: [],
      items: [],
      extra_data: {
        mission: {
          kyc: {
            total: 0,
            success: 0,
            pending: 0
          },
          deposit: {
            total: 0,
            success: 0,
            pending: 0
          }
        },
        report_status: {},
        report_status_bonus: {}
      },
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },

    };
  },
  created() {
    this.loadMissions();
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê thử thách", route: "/campaign/report/refers" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletCampaignRepository.reportRefers(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        this.items = body.data;
        this.paginate = body.meta;
        this.extra_data = body.extra_data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },
    async loadMissions() {
      try {
        let response = await WalletCampaignRepository.getList();
        this.missions = response.data.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    buildStep (item) {
      if (item.status.value === 1) {
          return '-';
      } else {
        let title = '';
        item.steps.forEach(step => {
          if (step.is_current) {
            title = step.title;
          }
        });
        return title;
      }
    },

  },
};
</script>

<style >
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
</style>
